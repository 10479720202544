import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  
  const [error, setError] = useState('');
  
  const validate=()=>{
    let result=true;
    if( email==='' || email===null ) {
      result=false;
      alert('Enter email');
    }

    if( password === '' || password === null ) {
      result=false;
      alert('Enter email');
    }
    return result;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(validate()) {
      try {
        // const device_type = "Andoid"
        const response = await axios.post('https://app.finscanner.ai/finscanner-admin-api/api/login', { email, password});
        localStorage.setItem('token', response.data.token);
        window.location.href = '/dashboard'; // Redirect after successful login
      } catch (err) {
        setError('Login failed. Please check your credentials.');
      }
    }
  };

  return (
    <>
      <div className="modal-dialog login" id="mylogin">
        <div className="modal-content">
          <div class="row">
            <div class="mb-4 text-center login-box">
              <a href="https://finscanner.ai/" target="_blank" rel="noopener noreferrer" class="img-link">
                <img src="assets/images/fins_logo.svg" alt="logo-img" width="200" class="mb-2 fins-logo" />
              </a>
            </div>
          </div>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="row gy-3 gy-md-4 overflow-hidden">
              <div className="form-inp">
                <input
                  type="email"
                  name='email'
                  className="form-control mail-inp"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email"
                  autoComplete="off"
                />
              </div>
              <div className="form-inp">
                <div className="inp-sec">
                  <input
                    className="form-control pass-inp"
                    type="password"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="Password"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="forgot-pass d-flex justify-content-end">
                <Link to="/forgot-password">
                  <h5>Forgot Password</h5>
                </ Link> 
              </div>
              <div className="signin-btn d-flex justify-content-center">
                <div className="cta_btn button-1">
                  <button type='submit'>Sign in</button>
                </div>
              </div>
              {error && <p>{error}</p>}
            </div>
          </form>
          <div className="mt-4 text-center loggin-link">
            <span className="text-center text-decoration-none btn-sign">Don&apos;t have an account? <Link to="/sign-up" className="btn-sign-span cursor-pointer"> Sign Up</Link></span>
          </div>
        </div>
      </div>
    </>
  );
}
