import React from 'react';
import { Link } from 'react-router-dom';

export default function Integration() {
  return (
    <>
       <div className="main-outer">
            <div className="model">
                <div className="upload-file-sec">
                    <h3><Link to="/"><span>Dashboard &gt;</span></Link> Integration</h3>
                    <div className="filter-buttons">
                        <div className="view-button list-view-button active"><i className="fa fa-bars" aria-hidden="true"></i></div>
                        <div className="view-button grid-view-button"><i className="fa fa-th-large" aria-hidden="true"></i></div>
                    </div>
                </div>
            </div>
            <div className="outer ">
                <ol className="list list-view-filter">
                    <li className="card my-2">
                        <div className="card-body card-body-content ">
                            <div className="list-group-item list-group-item-action text-start fs-4 card-content">
                                <div className="card-content">
                                    <img src="../assets/images/zoho.svg" alt="zoho books" />
                                    <h4>Zoho books </h4>
                                </div>
                                <span className="text-end fs-4 button-1 cta_btn">
                                    <button className="primary-button">Add</button>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li className="card my-2">
                        <div className="card-body card-body-content ">
                            <div className="list-group-item list-group-item-action text-start fs-4 card-content">
                                <div className="card-content">
                                    <img src="../assets/images/tallyERP9.svg" alt="Tally ERP9" />
                                    <h4>Tally ERP9 </h4>
                                </div>
                                <span className="text-end fs-4 cta_btn" data-bs-toggle="modal" data-bs-target="#myModalcontactus">
                                    <button className="contact-button">Contact Us</button>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li className="card my-2">
                        <div className="card-body card-body-content ">
                            <div className="list-group-item list-group-item-action text-start fs-4 card-content">
                                <div className="card-content">
                                    <img src="../assets/images/quickbooks.svg" alt="QuickBooks" />
                                    <h4>QuickBooks </h4>
                                </div>

                                <span className="text-end fs-4 cta_btn" data-bs-toggle="modal" data-bs-target="#myModalcontactus">
                                    <button className="contact-button">Contact Us</button>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li className="card my-2">
                        <div className="card-body card-body-content ">
                            <div className="list-group-item list-group-item-action text-start fs-4 card-content">
                                <div className="card-content">
                                    <img src="../assets/images/wave.svg" alt="Wave" />
                                    <h4>Wave </h4>
                                </div>

                                <span className="text-end fs-4 cta_btn" data-bs-toggle="modal" data-bs-target="#myModalcontactus">
                                    <button className="contact-button">Contact Us</button>
                                </span>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    </>
  )
}
