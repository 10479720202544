import React from 'react';
import SignupForm from '../components/SignupForm'

export default function SignUpPage() {
  return (
    <>
      <SignupForm />
    </>
  )
}
