import React from 'react';
import { Link } from 'react-router-dom';

export default function ApiPage() {
  return (
    <div className="main-outer">
        <div className="model">
            <div className="upload-file-sec">
                <h3>
                    <a href="../index.html"><span>Dashboard &gt;</span></a> API
                </h3>
                <div className="btn-sec btn_group">
                    <div className="button-1 cta_btn icon-btn">
                        <Link to="/" className="primary-button " data-bs-toggle="modal" data-bs-target="#myModaluploadfile"><i className="fa-solid fa-plus"></i> Upload File</Link>
                    </div>
                    <div className="button-2 cta_btn icon-btn">
                        <Link to="/" className="secondary-button dropdown-toggle" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"><i className="fa-solid fa-arrow-down"></i>
                            Download extracted data
                        </Link>
                        <ul className="dropdown-menu download-dropdown" aria-labelledby="defaultDropdown">
                            <li><Link to="/" className="dropdown-item">XLSX</Link></li>
                            <li><Link to="/" className="dropdown-item">CSV</Link></li>
                            <li><Link to="/" className="dropdown-item">XML</Link></li>
                            <li><Link to="/" className="dropdown-item">JSON</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="outer ">

        </div>
    </div>
  )
}
