import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SignupForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      name: name,
      email: email,
      password: password
  };
    try {
      const response = await fetch('https://app.finscanner.ai/finscanner-admin-api/api/signup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();
      
      if (response.ok) {
        console.log('Signup successful:', data);
      } else {
        console.error('Signup failed:', data);
      }

    } catch (error) {
      console.error('Error during signup:', error);
    }
  };

  return (
    <>
      <div className="modal-dialog login" id="mySignup">
        <div className="modal-content">
          <div class="row">
            <div class="mb-4 text-center login-box">
              <a href="https://finscanner.ai/" target="_blank" rel="noopener noreferrer" class="img-link">
                <img src="assets/images/fins_logo.svg" alt="logo-img" width="200" class="mb-2 fins-logo" />
              </a>
            </div>
          </div>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="row gy-3 gy-md-4 overflow-hidden">
              <div className="form-inp">
                <input 
                  className="form-control mail-inp"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  required
                />
              </div>
              <div className=" mt-2 form-inp">
                <input
                  className="form-control mt-3  mail-inp"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-inp">
                <div className="inp-sec">
                  <input
                    id="txtPassword"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="form-control pass-inp"
                    required
                  />
                </div>
              </div>
                <div className="signin-btn d-flex justify-content-center">
                  <div className="cta_btn button-1">
                    <button type="submit">Sign up</button>
                  </div>
                </div>
            </div>
          </form>
          <div className="mt-4 text-center loggin-link">
            <span className="text-center text-decoration-none btn-sign">Already have an account? <Link to="/login" className="btn-sign-span cursor-pointer"> Log In</Link></span>
          </div>
        </div>
      </div>
    </>
  );
};