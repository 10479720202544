import React from 'react';
import LoginForm from '../components/LoginForm';

export default function LoginPage() {
  return (
    <>
      <LoginForm />
    </>
  )
}
