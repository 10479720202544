import React from 'react';
import { Link } from 'react-router-dom';

export default function Documents() {
  return (
    <>
      <div className="main-outer">
        <div className="model">
            <div className="upload-file-sec">
                <h3><Link to="/"><span>Dashboard &gt;</span></Link> Documents</h3>
                <div className="btn-sec btn_group">
                    <div className="button-1 cta_btn icon-btn">
                      <Link to="/" className="primary-button "><i className="fa-solid fa-plus"></i> Create Document</Link>
                    </div>
                    <div className="button-2 cta_btn icon-btn">
                        <Link to="/" className="secondary-button dropdown-toggle" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"><i className="fa-solid fa-arrow-down"></i>Download extracted data</Link>
                        <ul className="dropdown-menu download-dropdown" aria-labelledby="defaultDropdown">
                          <li><Link to="/" className="dropdown-item">XLSX</Link></li>
                          <li><Link to="/" className="dropdown-item">CSV</Link></li>
                          <li><Link to="/" className="dropdown-item">XML</Link></li>
                          <li><Link to="/" className="dropdown-item">JSON</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="outer ">
            <div className="file-sec">
                <div className="search-sec doc-search-sec">
                    <form className="input-group">
                        <div className="form-outline has-search" data-mdb-input-init="">
                            <input id="search-sec" type="text" className="form-control form-control-inp" placeholder="Search" />
                            <button className="form-inp-btn" type="submit">
                                <span className="fa fa-search form-control-feedback"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="doc-sec d-flex justify-content-between">
                <div className="doc-sec1">
                    <div className="doc-title d-flex justify-content-between">
                        <div>
                            <h5>Sent Documents by day</h5>
                        </div>
                        <div className="sec-time d-flex">
                            <h5>All</h5>
                            <h5>Last 7 Days</h5>
                        </div>
                    </div>
                </div>
                <div className="doc-sec2">
                    <div className="doc-title d-flex justify-content-between">
                        <div>
                            <h5>Sent Documents by Status</h5>
                        </div>
                        <div>
                            <h5>Last 30 days</h5>
                        </div>
                    </div>
                    <div className="chart-sec">
                        <div className="card my-3">
                            <div className="card-body p-3">
                                <div className="chart">
                                    <canvas id="line-chart" className="chart-canvas" height="300px"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="doc-table table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th><Link to="/" title="Document Title">Document Title</Link></th>
                    <th><Link to="/" title="Document ID">Document Type</Link></th>
                    <th><Link to="/" title="Assign to">Owner</Link></th>
                    <th><Link to="/" title="Issued at">Amount</Link></th>
                    <th><Link to="/" title="Status">Status</Link></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><Link to="/">1</Link></td>
                    <td><Link to="/" title="Sample Name">Branding Proposal</Link></td>
                    <td><Link to="/" title=""> Proposal</Link></td>
                    <td><Link to="/" title="">Admin</Link></td>
                    <td><Link to="/" title="">-</Link></td>
                    <td><Link to="/" title="" className="in-review">Draft</Link></td>
                    <td>
                      <Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                      </Link>
                      <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li>
                          <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>ELinkit</Link>
                        </li>
                        <li>
                          <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>Delete</Link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </>
  )
}
