import React from 'react';
import { Link } from 'react-router-dom';

export default function SidebarNav() {
  return (
    <div className="app-sidebar">
        <div className="top-sec">
            <div className="myhome-sec">
                <div className="accordion accordion-flush workspace-accordion" id="accordionFlushExample">
                    <div className="accordion-item accordion-not">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <i className="fa-solid fa-house sidebar-icon"></i>
                                <h5 className="sidebar-heading">My Workspace</h5>
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <div id="home-collapse" className="home-collapse ">
                                    <ul className="btn-toggle-nav">
                                        <li className="active invoice-sec">
                                            <Link to="/" className="invoice-sec-link" title="Invoice">
                                                <i className="fas fa-file-invoice invoice-sec-icon"></i>
                                                Invoice
                                            </Link>
                                            <Link to="/" className="dropdown-toggle invoice-dropdown-toggle" id="defaultDropdownq" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                <i className="fas fa-ellipsis-v edit-icon"></i>
                                            </Link>
                                            <ul className="dropdown-menu invoice-dropdown" aria-labelledby="defaultDropdownq">
                                                <li><Link to="/" className="dropdown-item"><i className="fas fa-edit"></i> Edit</Link></li>
                                                <li><Link to="/" className="dropdown-item" data-bs-toggle="modal" data-bs-target="#myModalnew"><i className="fa-solid fa-key"></i> Add Model</Link></li>
                                                <li><Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i> Delete</Link></li>
                                            </ul>
                                        </li>

                                        <li>
                                            <Link to="/" title="">
                                                <i className="fa-solid fa-receipt"></i>
                                                Receipt
                                                <span>(5)</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/" title="">
                                                <i className="fa-solid fa-money-bill fa-rotate-90 bill-icon"></i>
                                                Bill
                                                <span>(0)</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                            <Link to="/documents" className="accordion-button collapsed btn-hidden">
                                <i className="fa-solid fa-file sidebar-icon"></i>
                                <h5 className="text-capitalize sidebar-heading">Documents</h5>
                            </Link>
                        </h2>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFour">
                            <Link to="/api" title="API" className="accordion-button collapsed btn-hidden">
                                <i className="fa-solid fa-database sidebar-icon"></i>
                                <h5 className="text-capitalize sidebar-heading">API</h5>
                            </Link>
                        </h2>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFive">
                            <Link to="/integration" title="Integration" className="accordion-button collapsed btn-hidden">
                                <i className="fa-solid fa-file-code sidebar-icon"></i>
                                <h5 className="text-capitalize sidebar-heading">Integration</h5>
                            </Link>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}