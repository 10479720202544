import React ,{ useState } from 'react';
import { Link } from 'react-router-dom';


const AllTable = () => (
    <table id="file-info" className="table all" >
        <thead>
            <tr>
                <th>#</th>
                <th><Link to="/" title="File Name">File Name</Link></th>
                <th><Link to="/" title="Document ID">Document ID</Link></th>
                <th><Link to="/" title="Order number">Order number</Link></th>
                <th><Link to="/" title="Assign to">Assign to</Link></th>
                <th><Link to="/" title="Issued at">Issued at</Link></th>
                <th><Link to="/" title="uploaded at">uploaded at</Link></th>
                <th><Link to="/" title="Seller Name">Seller Name</Link></th>
                <th><Link to="/" title="Invoice Amt">Invoice Amt</Link></th>
                <th><Link to="/" title="Status">Status</Link></th>
                <th> </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="Sample Name">invoice 1</Link></td>
                <td><Link to="/" title=""> 54561</Link></td>
                <td><Link to="/" title="">p 54561</Link></td>
                <td><Link to="/" title="">Service</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">Global Corp.</Link></td>
                <td><Link to="/" title="">201.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle " type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">2</Link></td>
                <td><Link to="/" title="" className="">invoice 2</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">02-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">202.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">3</Link></td>
                <td><Link to="/" title="" className="">invoice 3</Link></td>
                <td><Link to="/" title="" className=""> 54563</Link></td>
                <td><Link to="/" title="" className="">p 54563</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">03-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">203.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">4</Link></td>
                <td><Link to="/" title="" className="">invoice 4</Link></td>
                <td><Link to="/" title="" className=""> 54564</Link></td>
                <td><Link to="/" title="" className="">p 54564</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">04-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">204.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">5</Link></td>
                <td><Link to="/" title="" className="">invoice 5</Link></td>
                <td><Link to="/" title="" className=""> 54565</Link></td>
                <td><Link to="/" title="" className="">p 54565</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">05-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">205.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
        </tbody>
    </table>
    
);
  
const ApprovedTable = () => (
    <table id="file-info2" className="table approved">
        <thead>
            <tr>
                <th>#</th>
                <th><Link to="/" title="File Name">File Name</Link></th>
                <th><Link to="/" title="Document ID">Document ID</Link></th>
                <th><Link to="/" title="Order number">Order number</Link></th>
                <th><Link to="/" title="Assign to">Assign to</Link></th>
                <th><Link to="/" title="Issued at">Issued at</Link></th>
                <th><Link to="/" title="uploaded at">uploaded at</Link></th>
                <th><Link to="/" title="Seller Name">Seller Name</Link></th>
                <th><Link to="/" title="Invoice Amt">Invoice Amt</Link></th>
                <th><Link to="/" title="Status">Status</Link></th>
                <th> </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="" className="">invoice 2</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Assign to</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">5</Link></td>
                <td><Link to="/" title="" className="">invoice 5</Link></td>
                <td><Link to="/" title="" className=""> 54565</Link></td>
                <td><Link to="/" title="" className="">p 54565</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">05-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">205.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">6</Link></td>
                <td><Link to="/" title="" className="">invoice 6</Link></td>
                <td><Link to="/" title="" className=""> 54566</Link></td>
                <td><Link to="/" title="" className="">p 54566</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">06-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">206.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="" className="">invoice 2</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Assign to</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
            <tr>
                <td><Link to="/">5</Link></td>
                <td><Link to="/" title="" className="">invoice 5</Link></td>
                <td><Link to="/" title="" className=""> 54565</Link></td>
                <td><Link to="/" title="" className="">p 54565</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">05-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">205.95</Link></td>
                <td><Link to="/" title="" className="approve">Approve</Link></td>
                <td><Link to="/" title="" className="">
                        <i className="fa-solid fa-download"></i></Link>
                    <Link to="/" title="" className="delete">
                        <i className="fa-solid fa-trash"></i></Link>
                </td>
            </tr>
        </tbody>
    </table>
);

const InReviewTable = () => (
    <table id="file-info3" className="table review">
        <thead>
            <tr>
                <th>#</th>
                <th><Link to="/" title="File Name">File Name</Link></th>
                <th><Link to="/" title="Document ID">Document ID</Link></th>
                <th><Link to="/" title="Order number">Order number</Link></th>
                <th><Link to="/" title="Assign to">Assign to</Link></th>
                <th><Link to="/" title="Issued at">Issued at</Link></th>
                <th><Link to="/" title="uploaded at">uploaded at</Link></th>
                <th><Link to="/" title="Seller Name">Seller Name</Link></th>
                <th><Link to="/" title="Invoice Amt">Invoice Amt</Link></th>
                <th><Link to="/" title="Status">Status</Link></th>
                <th> </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="" className="">Invoice 4</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Assign to</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="Sample Name">invoice 1</Link></td>
                <td><Link to="/" title=""> 54561</Link></td>
                <td><Link to="/" title="">p 54561</Link></td>
                <td><Link to="/" title="">Service</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">Global Corp.</Link></td>
                <td><Link to="/" title="">201.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">7</Link></td>
                <td><Link to="/" title="" className="">invoice 7</Link></td>
                <td><Link to="/" title="" className=""> 54567</Link></td>
                <td><Link to="/" title="" className="">p 54567</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">07-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">207.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="" className="">Invoice 4</Link></td>
                <td><Link to="/" title="" className=""> 54562</Link></td>
                <td><Link to="/" title="" className="">p 54562</Link></td>
                <td><Link to="/" title="" className="">Assign to</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
                
            </tr>
            <tr>
                <td><Link to="/">1</Link></td>
                <td><Link to="/" title="Sample Name">invoice 1</Link></td>
                <td><Link to="/" title=""> 54561</Link></td>
                <td><Link to="/" title="">p 54561</Link></td>
                <td><Link to="/" title="">Service</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">28-11-2023</Link></td>
                <td><Link to="/" title="">Global Corp.</Link></td>
                <td><Link to="/" title="">201.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">7</Link></td>
                <td><Link to="/" title="" className="">invoice 7</Link></td>
                <td><Link to="/" title="" className=""> 54567</Link></td>
                <td><Link to="/" title="" className="">p 54567</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">07-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">207.95</Link></td>
                <td><Link to="/" title="" className="in-review">In-review</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
);

const RejectTable = () => (
    <table id="file-info4" className="table rejected">
        <thead>
            <tr>
                <th>#</th>
                <th><Link to="/" title="File Name">File Name</Link></th>
                <th><Link to="/" title="Document ID">Document ID</Link></th>
                <th><Link to="/" title="Order number">Order number</Link></th>
                <th><Link to="/" title="Assign to">Assign to</Link></th>
                <th><Link to="/" title="Issued at">Issued at</Link></th>
                <th><Link to="/" title="uploaded at">uploaded at</Link></th>
                <th><Link to="/" title="Seller Name">Seller Name</Link></th>
                <th><Link to="/" title="Invoice Amt">Invoice Amt</Link></th>
                <th><Link to="/" title="Status">Status</Link></th>
                <th> </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><Link to="/">12</Link></td>
                <td><Link to="/" title="" className="">invoice 12</Link></td>

                <td><Link to="/" title="" className=""> 54572</Link></td>
                <td><Link to="/" title="" className="">p 54572</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">12-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">3</Link></td>
                <td><Link to="/" title="" className="">invoice 3</Link></td>
                <td><Link to="/" title="" className=""> 54563</Link></td>
                <td><Link to="/" title="" className="">p 54563</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">03-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">203.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">8</Link></td>
                <td><Link to="/" title="" className="">invoice 8</Link></td>
                <td><Link to="/" title="" className=""> 54568</Link></td>
                <td><Link to="/" title="" className="">p 54568</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">08-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">208.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">9</Link></td>
                <td><Link to="/" title="" className="">invoice 9</Link></td>
                <td><Link to="/" title="" className=""> 54569</Link></td>
                <td><Link to="/" title="" className="">p 54569</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">09-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">209.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">12</Link></td>
                <td><Link to="/" title="" className="">invoice 12</Link></td>

                <td><Link to="/" title="" className=""> 54572</Link></td>
                <td><Link to="/" title="" className="">p 54572</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">12-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">212.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">3</Link></td>
                <td><Link to="/" title="" className="">invoice 3</Link></td>
                <td><Link to="/" title="" className=""> 54563</Link></td>
                <td><Link to="/" title="" className="">p 54563</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">03-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">203.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">8</Link></td>
                <td><Link to="/" title="" className="">invoice 8</Link></td>
                <td><Link to="/" title="" className=""> 54568</Link></td>
                <td><Link to="/" title="" className="">p 54568</Link></td>
                <td><Link to="/" title="" className="">Product</Link></td>
                <td><Link to="/" title="" className="">08-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">208.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td><Link to="/">9</Link></td>
                <td><Link to="/" title="" className="">invoice 9</Link></td>
                <td><Link to="/" title="" className=""> 54569</Link></td>
                <td><Link to="/" title="" className="">p 54569</Link></td>
                <td><Link to="/" title="" className="">Service</Link></td>
                <td><Link to="/" title="" className="">09-11-2023</Link></td>
                <td><Link to="/" title="" className="">28-11-2023</Link></td>
                <td><Link to="/" title="" className="">Global Corp.</Link></td>
                <td><Link to="/" title="" className="">209.95</Link></td>
                <td><Link to="/" title="" className="reject">Rejected</Link></td>
                <td><Link to="/" title="" className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-v file-dropdown-icon"></i>
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                        <li> <Link to="/" className="dropdown-item"><i className="fas fa-edit"></i>
                                Edit</Link></li>
                        <li> <Link to="/" className="dropdown-item"><i className="fa fa-sign-out"></i>
                                Delete</Link></li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
);

export default function Dashboard() {
    const [modal] = useState(null);
    const [activeTab, setActiveTab] = useState('tab1');

    return (
        <div className="main-outer">
            <div className="model">
                <div className="upload-file-sec">
                    <h3><span>Dashboard</span></h3>
                    <div className="btn-sec btn_group">
                        <div className="button-1 cta_btn icon-btn">
                            <Link to="/" onClick={() => modal.show()} className="primary-button " data-bs-toggle="modal" data-bs-target="#myModaluploadfile"><i className="fa-solid fa-plus"></i> Upload File</Link>
                        </div>
                        <div className="button-2 cta_btn icon-btn">
                            <Link to="/" className="secondary-button dropdown-toggle drop-down-hover" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"><i className="fa-solid fa-arrow-down"></i>
                                Download extracted data
                            </Link>
                            <ul className="dropdown-menu download-dropdown" aria-labelledby="defaultDropdown">
                                <li> <Link to="/" className="dropdown-item">XLSX</Link></li>
                                <li> <Link to="/" className="dropdown-item">CSV</Link></li>
                                <li> <Link to="/" className="dropdown-item">XML</Link></li>
                                <li> <Link to="/" className="dropdown-item">JSON</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="outer">
                <div class="file-sec">
                    <div class="file-type">
                    <button className={`target-link ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => setActiveTab('tab1')} >
                        all files<span class="doc_no"></span>(3)
                    </button>
                    <button className={`target-link ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => setActiveTab('tab2')} >
                        approved<span class="doc_no">(1)</span>
                    </button>
                    <button className={`target-link ${activeTab === 'tab3' ? 'active' : ''}`} onClick={() => setActiveTab('tab3')} >
                        in review<span class="doc_no">(2)</span>
                    </button>
                    <button className={`target-link ${activeTab === 'tab4' ? 'active' : ''}`} onClick={() => setActiveTab('tab4')} >
                        rejected<span class="doc_no">(0)</span>
                    </button>
                        <a href="javascript:void(0)" title="All Files" class=" active" data-tab-target=".all">
                            </a>
                        <a href="javascript:void(0)" title="Approved" class="target-link" data-tab-target=".approved">
                            </a>
                        <a href="javascript:void(0)" title="Review" class="target-link" data-tab-target=".review">
                            </a>
                        <a href="javascript:void(0)" title="Rejected" class="target-link" data-tab-target=".rejected">
                            </a>
                    </div>
                    <div class="search-sec">
                        <form class="input-group">
                            <div class="form-outline has-search" data-mdb-input-init="">
                                <input id="search-sec" type="text" class="form-control form-control-inp" placeholder="Search" />
                                <button class="form-inp-btn" type="submit">
                                    <span class="fa fa-search form-control-feedback"></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="tab-content">
                    <div className='table-responsive'>
                        {activeTab === 'tab1' && <AllTable />}
                        {activeTab === 'tab2' && <ApprovedTable />}
                        {activeTab === 'tab3' && <InReviewTable />}
                        {activeTab === 'tab4' && <RejectTable />}
                    </div>
                </div>
            </div>
        </div>
    )
}
